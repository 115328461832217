import React from 'react';
import { Form, Field } from 'react-final-form';
import { setIn } from 'final-form';
import * as yup from 'yup';
import { withRouter, Link } from "react-router-dom";
import { CPF, TextField } from '../../components/inputs/InputsMasks';
import { DefaultButton, ButtonBranco } from "../../components/buttons/Buttons"
import server from "../../services/api";
import { login } from '../../services/auth';
import { Erro } from '../../components/texts/Erro';
import FormOption from '../../components/forms/FormOption';
import { Explanation, Title } from '../../components/texts/Title';
import "../../styles/body.scss"
import { TypeToast } from '../../components/toast/TypeToast';
import TypeStatusReqs from '../../services/statusRequisicoes';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            schema: yup.object().shape({
                cpf: yup.string().test("validar_cpf", "CPF inválido", (strCPF) => {
                    if (!strCPF)
                        return false;

                    if (strCPF.length >= 11) {
                        return true
                    }
                    // strCPF = strCPF.replace(/\D/g, '')
                    // let Soma;
                    // let Resto;
                    // Soma = 0;
                    // if (strCPF === "00000000000") return false;
                    // for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                    // Resto = (Soma * 10) % 11;
                    // if ((Resto === 10) || (Resto === 11)) Resto = 0;
                    // if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
                    // Soma = 0;
                    // for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                    // Resto = (Soma * 10) % 11;
                    // if ((Resto === 10) || (Resto === 11)) Resto = 0;
                    // if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
                    // return true;
                }).required("CPF é obrigatório").nullable(true),
                senha: yup.string()
                    .required("Senha é obrigatória"),
            }),
            loading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    validate = async (values) => {
        try {
            await this.state.schema.validate(values, { abortEarly: false });
        } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
                return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
        }
    };

    handleSubmit(object) {
        const newObject = this.state.schema.cast(object);
        this.setState({ loading: true })
        server
            .post("/user/login", newObject)
            .then(
                (res) => {
                    this.setState({ loading: false })
                    login({
                        token: res.data.token,
                        nome: res.data.usuario.nome,
                        admin: res.data.usuario.admin,
                    });
                    this.props.history.push("/dashboard")
                }, (err) => {
                    if (err.response.data.status && err.response.status === 401) {
                        this.setState({ msg: "Usuário ou senha inválidos" })
                    }

                    this.setState({ loading: false })
                    TypeToast(TypeStatusReqs(err), "error")
                })
    }

    render() {
        return (
            <Form
                onSubmit={this.handleSubmit}
                validate={this.validate}
                render={({ handleSubmit, form }) => (
                    <FormOption
                        cabecalho={
                            <>
                                <div className="mb-3">
                                    <Title label="Não tem um acesso?" style={{ fontSize: "24px" }} />
                                </div>
                                <div className="mb-5">
                                    <Explanation color="#333333" label="Acesse sem um login para fazer a reserva de quadras e outros recursos disponíveis." />
                                </div>
                            </>
                        }
                        rodape={
                            <Link to="/area-comum/agendamento/">
                                <ButtonBranco label="Acessar" />
                            </Link>
                        }
                        onSubmit={handleSubmit}
                    >
                        <div className="fixed-top text-left mb-sm-4 mb-2" style={{ zIndex: -1, right: "4%" }}>
                            <Link to='/'>
                                <img
                                    src="/logo.png"
                                    alt="logo"
                                    style={{ maxWidth: "120px" }}
                                />
                            </Link>
                        </div>
                        <div className="text-center">
                            <Title label="LOGIN" color="black" style={{ fontSize: "32px" }} />
                        </div>
                        {this.state.msg ? <div className="alert alert-danger text-center">
                            {this.state.msg}
                        </div> : null}
                        <Field name="cpf">
                            {({ input, meta }) => (
                                <div className="form-group mb-5 mt-5 col">
                                    <CPF
                                        {...input}
                                        id="cpf"
                                        type="tel"
                                        semMasck={true}
                                    />
                                    <Erro
                                        touched={meta.touched}
                                        error={meta.error && meta.error}
                                    />
                                </div>
                            )}
                        </Field>
                        <Field name="senha">
                            {({ input, meta }) => (
                                <div className="form-group mb-3 text-left col pb-5">
                                    <TextField
                                        {...input}
                                        label="Senha"
                                        placeholder="Sua senha"
                                        type="password"
                                        id="senha"
                                    />
                                    <Erro
                                        touched={meta.touched}
                                        error={meta.error && meta.error}
                                    />
                                </div>
                            )}
                        </Field>
                        <div className='btnBox'>
                            <div>
                                <Link to="/esqueceu-a-senha">Esqueceu sua senha?</Link>
                            </div>
                            <div>
                                <DefaultButton label="Continuar" loading={this.state.loading} labelLoading="Logando" />
                            </div>
                        </div>
                    </FormOption>
                )}
            />
        )
    }

}

export default withRouter(LoginPage)
// Funções que retornam dados do token e do login do usuário
// responsável por fazer o login e logout também
export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const ADMIN = "admin"
export const NOME = "nome"
// export const isAuthenticated = () => true;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const login = obj => {
  localStorage.setItem(TOKEN_KEY, obj.token);
  localStorage.setItem(ADMIN, obj.admin)
  localStorage.setItem(NOME, obj.nome)
  if (obj.refreshToken)
    localStorage.setItem(REFRESH_TOKEN_KEY, obj.refreshToken);
};

export const logout = () => {
  localStorage.clear()
  window.location.href = "/login"
};

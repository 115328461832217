import React from 'react';
import { Form } from 'react-final-form';
import { withRouter, Link } from "react-router-dom";
import { DefaultButton, SecondaryButton } from "../../components/buttons/Buttons"
import server from "../../services/api";
import { Placeholder, TitleStep } from '../../components/texts/Title';
import { TypeToast } from '../../components/toast/TypeToast';
import TypeStatusReqs from '../../services/statusRequisicoes';
import "../../styles/body.scss"


class ConfirmEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            enviado: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        this.setState({ loading: true })
        server
            .post("/user/envio-email", { cpf: this.props.location.state.cpf })
            .then(
                (res) => {
                    this.setState({ enviado: true })
                    //set em state e mostrar que um email foi enviado
                }, (err) => {
                    TypeToast(TypeStatusReqs(err), "error")
                }).finally(() => this.setState({ loading: false }))
    }
    render() {

        return (
            <Form
                onSubmit={this.handleSubmit}
                render={({ handleSubmit }) => (
                    <div style={{ width: '70%', backgroundColor: '#fff', margin: '55px auto 0', padding: '3rem', borderRadius: '8px' }}>
                        {this.state.enviado ?
                            <>
                                <div className='w-100'>
                                    <TitleStep label='Email enviado' />
                                </div>
                                <div className='mt-3'>
                                    <Placeholder>Confira o seu e-mail para prosseguir com a troca de senha.</Placeholder>
                                </div>
                                <div className='w-100'>
                                    <div className="pt-5 text-right">
                                        <Link to='/login'>
                                            <DefaultButton label="Confirmar" loading={this.state.loading} />
                                        </Link>
                                    </div>
                                </div>
                            </>
                            : <form onSubmit={handleSubmit}>
                                <div className="fixed-top text-left mb-sm-4 mb-2" style={{ zIndex: -1, right: "4%" }}>
                                    <Link to='/'>
                                        <img
                                            src="/logo.png"
                                            alt="logo"
                                            style={{ maxWidth: "120px" }}
                                        />
                                    </Link>
                                </div>
                                <div className="">
                                    <TitleStep label="Confirme seu e-mail" />
                                </div>
                                <div className=" mt-3">
                                    <Placeholder>
                                        Um e-mail será enviado para: <strong>{this.props.location.state.email}</strong>, com o link para a troca de senha.
                                    </Placeholder>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="pt-5 text-left">
                                        <Link to="/login"><SecondaryButton label="Cancelar" /></Link>
                                    </div>
                                    <div className="pt-5 text-right">
                                        <DefaultButton label="Enviar" loading={this.state.loading} />
                                    </div>
                                </div>
                            </form>}
                    </div>
                )}
            />
        )
    }

}

export default withRouter(ConfirmEmail);
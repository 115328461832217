import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import Header from "./../../components/header/Header";
import FooterHome from "./../../components/footer/Footer";
import Carousel from "./../../components/carousel/Carousel";
import { ButtonLaranja } from "../../components/buttons/Buttons";
import { stub } from "./stub";
import "./home.scss";
import wave from '../../images/v1.svg'
// import { Modal } from "@material-ui/core";
import vid from '../../videos/apresentacao.mp4'
import facha from '../../images/fachadArena.jpg'

function Card(props) {
  return (
    <div className="card-servicos my-4" key={props.key}>
      <img className="card-img-top" src={props.item.src} alt="esporte 1" />
      {props.hasButton && (
        props?.link ?
          <a rel="noopener noreferrer" target="_blank" href="https://wa.me/5555996389103?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20aulas">
            <ButtonLaranja label="Entre em contato" className="btn-custom button-laranja ml-4 font-quicksand--medium"/>
          </a>
          :
          <Link to={`/area-comum/agendamento/?id=${props.item.id}`} >
            <ButtonLaranja label="Reservar" className="btn-custom button-laranja ml-4 font-quicksand--medium" />
          </Link>
      )}
      <div className="card-body pt-4 px-4">
        <span className="font-quicksand--semibold">{props.item.name}</span>
        <p className="font-quicksand--medium">{props.item.description}</p>
      </div>
    </div>
  );
}

function ServicosHome() {
  const [servicos] = useState(stub.servicos);
  return (
    <div className="servicos-home" id="servicos">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center titles">
              <h3 className="font-quicksand--bold color-text-gradient">Conheça todo o nosso complexo esportivo</h3>
              <span className="font-quicksand--normal">É mais que uma arena de Beach Sports, é um ambiente inovador e acolhedor, para todas as idades.</span>
            </div>
          </div>
        </div>
        <Carousel>
          {servicos.map((item, i) => (
            <Card item={item} key={i} link={item?.link} hasButton={item?.hasButton} />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

function Viewer360Home() {
  return (
    <div className="viewer-360-home" id="360">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="landform"></div>
            <div className="viewer-360-content text-center d-flex align-items-center justify-content-center flex-column h-100">
              <span className="font-quicksand--bold">O primeiro da região</span>
              <p className="font-quicksand--medium mt-3">Com uma das maiores e melhores estruturas do estado, criamos o ambiente ideal para atividades físicas e lazer, para você e toda sua família!</p>
              <Link to="/area-comum/agendamento/">
                <button className="btn-custom button-branco d-flex align-items-center justify-content-center w-25">
                  <span className="color-text-gradient font-quicksand--bold">Reservar um horário</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ApresentacaoHome() {

  // const [openModal, setopenModal] = useState(false);
  const [apresentacaoideo, setApresentacaoVideo] = useState(false);
  return (
    <div className="apresentacao-home" id="apresentacao">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="d-flex justify-content-center mt-5 flex-wrap">
              <img src="./images/nuvoni.svg" alt="Nuvoni" />
              {/* <img src="./images/sicredi.svg" alt="Sicredi" /> */}
              {/* <img src="./images/nuvoni.svg" alt="Nuvoni" /> */}
              {/* <img src="./images/steffen.svg" alt="Steffen Bebidas" /> */}
              {/* <img src="./images/casa_do_bife.svg" alt="Casa do Bife" /> */}
              {/* <img src="./images/nuvoni.svg" alt="Nuvoni" />
              <img src="./images/nuvoni.svg" alt="Nuvoni" />
              <img src="./images/nuvoni.svg" alt="Nuvoni" />
              <img src="./images/nuvoni.svg" alt="Nuvoni" /> */}
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="text-center titles mt-5">
              <h3 className="font-quicksand--bold color-text-gradient">Quer ver mais um pouquinho?</h3>
              <span className="font-quicksand--normal">Conheça um pouco no vídeo, mas venha pessoalmente também!</span>
              {/* <span onClick={() => { setopenModal(true) }}>.</span> */}
              {/* {openModal &&
                // <Modal
                //   center
                //   open={openModal}
                //   onClose={() => setopenModal(false)}
                //   onBackdropClick={() => setopenModal(false)}
                //   onEscapeKeyDown={() => setopenModal(false)}
                // // aria-labelledby="simple-modal-title"
                // // aria-describedby="simple-modal-description"
                // >
                //   <div className='text-center' style={{
                //     justifyContent: 'center',
                //     width: 100,
                //     // margin:'auto',
                //     // marginLeft: '650px',
                //     // border: '4px solid #000',
                //     // alignItems: 'center',
                //     display: 'flex',
                //     // height:'100%'
                //   }}>
                //     <img className='text-center'
                //       width='400px'
                //       display='flex'
                //       // aling-items='center'
                //       // border= '2px solid #000'
                //       // padding= 'theme.spacing(2, 4, 3)'


                //       src={require('../../images/Gabester.jpg')} onClick={() => setopenModal(false)} />
                //   </div>
                // </Modal>
              } */}
            </div>
          </div>
        </div>
        <div className="mx-auto mt-4 video-wrapper">
          <div className="apresentacao-video position-relative">
            {!apresentacaoideo ? (
              <>
                <img src={facha} alt="facha" />
                <img src="./images/play_video.svg" alt="Play" onClick={() => setApresentacaoVideo(true)} />
              </>
            ) : (
              <iframe title="{Video Promocional}" width="695" height="393" src={vid} allow="autoplay; encrypted-media"></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function EnderecoHome() {
  return (
    <div className="endereco-home" id="endereco">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="text-center titles mt-5">
              <h3 className="font-quicksand--bold color-text-gradient">Como nos encontrar</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="landform"></div>
      <iframe title="Mapa" className="mt-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3527.0302064668726!2d-54.47526278430419!3d-27.870342440533996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f935b42e666baf%3A0x4a71df3469f25cf2!2sAv.%20Am%C3%A9rica%2C%20820%20-%20Centro%2C%20Santa%20Rosa%20-%20RS%2C%2098900-000!5e0!3m2!1spt-BR!2sbr!4v1624969539729!5m2!1spt-BR!2sbr" width="100%" height="350" frameBorder="0" style={{ border: 0 }} aria-hidden="false" tabIndex="0"></iframe>
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="endereco-box text-center d-flex align-items-center justify-content-center flex-column p-3">
            <span className="font-quicksand--bold">Santa Rosa</span>
            <p className="font-quicksand--medium mb-0">Av. América, 820 - Centro - 98780-325 - RS</p>
          </div>
        </div>
      </div>
      <div className="endereco-reserve d-flex align-items-center justify-content-center">
        <div className=" titles text-center d-flex flex-column align-items-center justify-content-center">
          <h3 className="font-quicksand--bold">Beach Sports, uma nova forma de se divertir.</h3>
          <Link to="/area-comum/agendamento/">
            <button className="btn-custom button-branco d-flex align-items-center justify-content-center w-25">
              <span className="color-text-gradient font-quicksand--bold">Reservar um horário</span>
            </button>
          </Link>
        </div>
        <img className="endereco-wave" src={wave} alt="onda" />
      </div>
    </div>
  );
}

class Home extends React.Component {
  render() {
    return (
      <>
        <Header />
        <ServicosHome />
        <Viewer360Home />
        <ApresentacaoHome />
        <EnderecoHome />
        <FooterHome />
      </>
    );
  }
}

export default withRouter(Home);

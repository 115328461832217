import React from "react";
import Cleave from "cleave.js/react";
import "./inputs.scss";
import DatePicker from "react-date-picker";
import "../../styles/default.scss";
import "../calendarios/calendario.scss";
import Loader from "../loaders/Loader";
import  Icon  from "../../components/Icons/Icons";
//com a mask
// event.target.value
// sem a mask
// event.target.rawValue

function anime(id) {
  const input = document.getElementById(`${id}`);
  input.classList.add("on-focus");
}

function removeAnime(id) {
  const input = document.getElementById(`${id}`);
  input.addEventListener("blur", (e) => {
    if (!e.target.value || e.target.value === "-1") {
      input.classList.remove("on-focus");
    }
  });
}

export class CreditCard extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onCreditCardChange(event) {
    if (this.props.semMasck) {
      this.props.onChange(event.target.rawValue);
    } else {
      this.props.onChange(event.target.value);
    }
  }

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={{
            blocks: [4, 4, 4, 4],
            delimiter: " ",
            numericOnly: true,
          }}
          onChange={this.onCreditCardChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          value={this.props.value}
          autoComplete="off"
          type="tel"
        />
        <div className="input-label">
          <p>
            {this.props.placeholder
              ? this.props.placeholder
              : "Número do cartão de crédito"}{" "}
          </p>
        </div>
      </div>
    );
  }
}

export class Data extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onCreditCardExpiryChange(event) {
    if (this.props.semMasck) {
      this.props.onChange(event.target.rawValue);
    } else {
      this.props.onChange(event.target.value);
    }
  }

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={{
            date: true,
            datePattern: this.props.dataFormater
              ? this.props.dataFormater
              : ["d", "m", "Y"],
          }}
          onChange={this.onCreditCardExpiryChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          value={this.props.value}
          autoComplete="off"
          type="tel"
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "Data"}</p>
        </div>
      </div>
    );
  }
}

export class CVC extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onCVVChange(event) {
    if (this.props.semMasck) {
      this.props.onChange(event.target.rawValue);
    } else {
      this.props.onChange(event.target.value);
    }
  }

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={{
            blocks: [3],
            numericOnly: true,
          }}
          onChange={this.onCVVChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          value={this.props.value}
          autoComplete="off"
          type="tel"
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "CVV"} </p>
        </div>
      </div>
    );
  }
}
export class CUSTOM extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onCustomChange(event) {
    if (this.props.semMasck) {
      if (this.props.callBack) {
        this.props.callBack(event.target.rawValue);
      }
      if (this.props.onChange) {
        this.props.onChange(event.target.rawValue);
      }
    } else {
      if (this.props.onChange) {
        this.props.onChange(event.target.value);
      }
      if (this.props.callBack) {
        this.props.callBack(event.target.value);
      }
    }
  }

  onKeyPress(value) {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(value);
    }
  }

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={this.props.options}
          onChange={this.onCustomChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          value={this.props.value}
          autoComplete="off"
          type={this.props.type}
          onKeyPress={(e) => this.onKeyPress(e.key)}
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "CVC"} </p>
        </div>
      </div>
    );
  }
}

export class Phone extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onPhoneChange = (event) => {
    if (this.props.semMasck) {
      this.props.onChange(event.target.rawValue);
    } else {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={{
            blocks: [0, 2, 0, 5, 4],
            delimiters: ["(", ")", " ", "-"],
            numericOnly: true,
          }}
          onChange={this.onPhoneChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          value={this.props.value}
          autoComplete="off"
          type="tel"
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "Telefone"} </p>
        </div>
      </div>
    );
  }
}
export class TextField extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  componentDidUpdate() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onTextFieldChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
    if (this.props.callBack) {
      this.props.callBack(event.target.value);
    }
  };

  render() {
    return (
      <div className="input-container">
        <input
          disabled={this.props.disabled}
          autoComplete="off"
          type={this.props.type}
          value={this.props.value}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          onChange={this.onTextFieldChange.bind(this)}
          maxLength={this.props.maxLength}
        />
        <div className="input-label">
          <p>
            {this.props.placeholder
              ? this.props.placeholder
              : "Faltou o placeholder"}
          </p>
        </div>
      </div>
    );
  }
}

export class Select extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  handleChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    if (this.props.callBack) {
      this.props.callBack(event);
    }
  };

  render() {
    let { loading, labelloading, style } = this.props;
    let dados = this.props.dados;
    let label = this.props.labelProp || "label";
    let value = this.props.valueProp || "value";
    let key = this.props.keyProp || "id";
    let disabled = this.props.disabled;

    let options = dados.map((data) =>
      Object.entries(data).length > 0 ? (
        <option key={data[key]} value={data[value]} disabled={data[disabled]}>
          {data[label]}
        </option>
      ) : null
    );

    return (
      <div className="input-container fontQuick">
        <select
          name={this.props.name}
          className="input-field"
          onChange={this.handleChange}
          value={this.props.value}
          disabled={loading ? loading : this.props.disabled}
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          style={style}
        >
          <option value={-1} className="d-none">
            { }
          </option>
          {options}
        </select>
        <div className="input-label">
          {loading ? (
            <p>
              <Loader /> {labelloading}
            </p>
          ) : (
            <p>
              {this.props.placeholder ? this.props.placeholder : "Selecione"}
            </p>
          )}
        </div>

        <div className="invalid-feedback mt-0" style={{ height: 0 }}>
          {this.props.labelErro}
        </div>
      </div>
    );
  }
}
export class CPF extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onCPFChange = (event) => {
    if (this.props.semMasck) {
      this.props.onChange(event.target.rawValue);
    } else {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    return (
      <div className="input-container font-quicksand">
        <Cleave
          options={{
            blocks: [3, 3, 3, 2],
            delimiters: [".", ".", "-"],
            numericOnly: true,
          }}
          value={this.props.value}
          onChange={this.onCPFChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          autoComplete="off"
          type="tel"
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "CPF"} </p>
        </div>
      </div>
    );
  }
}

export class Dinheiro extends React.Component {
  componentDidMount() {
    if (this.props.value) anime(this.props.id);
  }

  moeda(i) {
    var v = i.value.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    let valor = `R$ ${v}`;
    this.onChange(valor);
  }

  onChange = (value) => {
    if (this.props.semMasck) {
      this.props.onChange(value.replace("R$ ", ""));
    } else {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <div className="input-container">
        <input
          value={this.props.value}
          className="input-field"
          id={this.props.id}
          autoComplete="off"
          type="tel"
          onChange={(e) => this.moeda(e.target)}
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "Dinheiro"} </p>
        </div>
      </div>
    );
  }
}

export class InputData extends React.Component {
  onInputData = (evento) => {
    if (this.props.onChange) {
      this.props.onChange(evento);
    }
    if (this.props.callBack) {
      this.props.callBack(evento);
    }
  };

  render() {
    return (
      <div className="input-container">
        <DatePicker
          format="dd/MM/y"
          onChange={this.onInputData.bind(this)}
          value={this.props.value}
          className="input-field input-data"
          minDetail="year"
          prev2Label={null}
          next2Label={null}
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export class InputRadio extends React.Component {
  onInputData = (evento, item) => {
    let itens = this.props.itens;
    for (let y in itens) {
      if (itens[y].value === evento) {
        itens[y].checked = true;
      }
    }

    if (this.props.onChange) {
      this.props.onChange(evento);
    }
    if (this.props.callBack) {
      this.props.callBack(evento, item);
    }
  };

  render() {
    return this.props.itens.map((item) => {
      if (item)
        return (
          <div
            key={item.value}
            className={`bulgy-radios ${this.props.className ? this.props.className : "col-md-6 col-12"
              } `}
            role="radiogroup"
            aria-labelledby="bulgy-radios-label"
          >
            <label>
              <input
                type="radio"
                name="options"
                checked={item.checked}
                onChange={() => this.onInputData(item.value, item)}
              />
              <span className="radio"></span>
              <span className="label" style={{ fontSize: "1.2rem" }}>
                {item.title}
              </span>
            </label>
          </div>
        );

      return null;
    });
  }
}

export class InputRadioCard extends React.Component {
  onInputData = (evento) => {
    Object.keys(this.props.itens).forEach((k) => {
      let dados = this.props.itens[k];
      if (dados.value === evento.value) {
        dados.checked = true;
      }
    });

    if (this.props.onChange) {
      this.props.onChange(evento);
    }
    if (this.props.callBack) {
      this.props.callBack(evento);
    }
  };

  render() {
    return Object.keys(this.props.itens).map((k) => {
      let dados = this.props.itens[k];
      return (
        <div
          className={`${this.props.className} ${dados.disabled ? "not-allowed" : ""
            }`}
          key={dados.title}
        >
          <div
            className={`card-radios bulgy-radios ${dados.value === this.props.value
              ? "select-border-inputRardio"
              : ""
              }  ${dados.disabled ? "disabled" : ""} `}
            role="radiogroup"
            aria-labelledby="bulgy-radios-labela"
            style={this.props.style}
          >
            <label style={{ width: "100%" }}>
              <input
                type="radio"
                name="optionss"
                disabled={dados.disabled}
                checked={dados.checked}
                onChange={() => this.onInputData(dados)}
              />
              {dados.disabled ? (
                <div className="radio-disabled">
                  <Icon icon={'times'} prefix={'fal fa-times'} size="2x" color="red" />
                </div>
              ) : (
                <span
                  className="radio"
                  style={{ top: "1.2rem", left: "5px" }}
                ></span>
              )}
              <div className="label mt-2">
                <div className="col-12">
                  <span className="" style={{ fontSize: "1.4rem" }}>
                    {dados.title}
                  </span>
                </div>
                <div className="col-12" style={{ marginTop: "-13px" }}>
                  <span className="" style={{ fontSize: "1rem" }}>
                    {dados.explanation}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </div>
      );
    });
  }
}

export class Numeros extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onNumerosChange = (event) => {
    if (this.props.onChange) {
      if (this.props.semMasck) {
        this.props.onChange(event.target.rawValue);
      } else {
        this.props.onChange(event.target.value);
      }
    }
    if (this.props.callBack) {
      if (this.props.semMasck) {
        this.props.callBack(event.target.rawValue);
      } else {
        this.props.callBack(event.target.value);
      }
    }
  };

  componentDidUpdate() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={{
            numericOnly: true,
          }}
          value={this.props.value}
          onChange={this.onNumerosChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          autoComplete="off"
          type="tel"
          maxLength={this.props.maxLength}
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "Números"} </p>
        </div>
      </div>
    );
  }
}

export class CEP extends React.Component {
  componentDidMount() {
    if (this.props.value) {
      anime(this.props.id);
    }
  }

  onCEPChange(event) {
    if (this.props.semMasck) {
      if (this.props.callBack) {
        this.props.callBack(event.target.rawValue);
      }
      if (this.props.onChange) {
        this.props.onChange(event.target.rawValue);
      }
    } else {
      if (this.props.onChange) {
        this.props.onChange(event.target.value);
      }
      if (this.props.callBack) {
        this.props.callBack(event.target.value);
      }
    }
  }

  render() {
    return (
      <div className="input-container">
        <Cleave
          options={{
            numera: true,
            blocks: [5, 3],
            delimiter: "-",
            numericOnly: true,
          }}
          onChange={this.onCEPChange.bind(this)}
          className="input-field"
          onFocus={() => anime(this.props.id)}
          onBlur={() => removeAnime(this.props.id)}
          id={this.props.id}
          value={this.props.value}
          autoComplete="off"
          type="tel"
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "CEP"} </p>
        </div>
        {this.props.loading ? (
          <div style={{ height: 0 }}>
            <Loader /> {this.props.labelloading}
          </div>
        ) : null}
      </div>
    );
  }
}

export class DataInput extends React.Component {
  componentDidMount() {
    anime(this.props.id);
  }

  onDataChange(event) {
    if (this.props.semMasck) {
      if (this.props.callBack) {
        this.props.callBack(event.target.rawValue);
      }
      if (this.props.onChange) {
        this.props.onChange(event.target.rawValue);
      }
    } else {
      if (this.props.onChange) {
        this.props.onChange(event.target.value);
      }
      if (this.props.callBack) {
        this.props.callBack(event.target.value);
      }
    }
  }
  render() {
    return (
      <div className="input-container">
        <input
          min={this.props.min}
          max={this.props.max ?? '9999-12-31'}
          type="date"
          placeholder={this.props.placeholder}
          onChange={this.onDataChange.bind(this)}
          className="input-field"
          id={this.props.id}
          value={this.props.value}
        />
        <div className="input-label">
          <p>{this.props.placeholder ? this.props.placeholder : "Data"} </p>
        </div>
        {this.props.loading ? (
          <div style={{ height: 0 }}>
            <Loader /> {this.props.labelloading}
          </div>
        ) : null}
      </div>
    );
  }
}



export class InputImg extends React.Component {

  constructor(props) {
    super(props);
    this.state = { img: '' }
  }

  onImgChange(event) {
    if (event.target.files.length) {
      this.setState({ img: event.target.files[0].name })
      this.props.onChange(event)
    }
  }

  render() {
    return (
      <div className="input-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={'input-label'}>
          <p style={{ fontSize: '1.25rem', fontfamily: "AmpleSoft_Pro_Regular", color: '#666666' }}>
            {this.props.placeholder ?? "Image"}{this.state.img ? `: ${this.state.img}` : ''}
          </p>
        </div>
        <input
          type="file"
          id={this.props.id}
          onChange={this.onImgChange.bind(this)}
          accept={this.props.accept}
          value={this.props.value}
          src={this.props.src}
          multiple={this.props.multiple}
        />
        <label htmlFor={this.props.id} className="btn-1"><Icon icon={'edit'} prefix={'fal fa-edit'} /> </label>
        {this.props.loading ? (
          <div style={{ height: 0 }}>
            <Loader /> {this.props.labelloading}
          </div>
        ) : null}
      </div>
    );
  }
}

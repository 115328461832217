import React from "react";
import { withRouter } from "react-router-dom";
import { NavBar } from "./../../components/navbar/NavBar";
import FooterHome from "./../../components/footer/Footer";
import { ButtonLaranja } from "../../components/buttons/Buttons";
import "./aulas.scss";
// import func from '../../images/funci.png'
import wave from '../../images/v1.svg'
import funcio from '../../images/funcional.jpg'

class Aulas extends React.Component {
  render() {
    return (
      <>
        <div className="aulas">
          <NavBar type="secondary" />
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-2">
                <div className="titles">
                  <h3 className="w-5 font-quicksand--bold color-text-gradient">Aulas</h3>
                </div>
              </div>
            </div>
            <div className="row mt-5 aula">
              <div className="col-lg-5 d-lg-flex align-items-center">
                <div className="titles">
                  <span className="font-quicksand--bold">Funcional na areia</span>
                  <p className="font-quicksand--medium">Já ouviu falar em treino funcional na areia? Já imaginou poder praticar exercício, orientado por um profissional capacitado, com o pé na areia e sensação de praia? Agende um horário e venha praticar com a gente!</p>
                  <a rel="noopener noreferrer" target="_blank" href="https://wa.me/5555996389103?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20aulas%20de%20Funcional%20na%20areia">
                    <ButtonLaranja label="Entre em contato" className="btn-custom button-laranja font-quicksand--medium" />
                  </a>
                </div>
              </div>
              <div className="col-lg-7 mt-sm-5 mt-lg-0 d-lg-flex align-items-center justify-content-end">
                <img src={funcio} className="img-fluid aulas-img" alt="Funcional de areia" />
              </div>
            </div>
            <div className="row mt-5 aula">
              <div className="col-lg-7 mt-sm-5 mt-lg-0 d-lg-flex align-items-center justify-content-start">
                <img src={require('../../images/areia2.jpg')} className="img-fluid aulas-img" alt="Beach Tennis" />
              </div>
              <div className="col-lg-5 d-lg-flex align-items-center">
                <div className="titles">
                  <span className="font-quicksand--bold">Beach Tennis</span>
                  <p className="font-quicksand--medium">E que tal praticar um dos esportes que mais cresce? Uma mistura de vôlei de praia, tênis e badminton. Parece complexo? Só parece, pois além de fácil é apaixonante. Faça uma aula e descubra você também essa novidade!</p>
                  <a rel="noopener noreferrer" target="_blank" href="https://wa.me/5555996389103?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20aulas%20de%20Beach%20Tennis">
                    <ButtonLaranja label="Entre em contato" className="btn-custom button-laranja font-quicksand--medium" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5">
              <div className="col-md-3 mt-5">
                <div className="titles">
                  <h3 className="font-quicksand--bold color-text-gradient">Professores</h3>
                </div>
              </div>
            </div> */}
            {/* <div className="row mt-4">
              <div className="col-lg-4 p-lg-2">
                <div className="professor-card d-flex justify-content-between align-items-start">
                  <div className="professor-card-info">
                    <h4 className="font-quicksand--bold">Estéfany Burg</h4>
                    <span className="font-quicksand--normal">Funcional de areia</span>
                    <div className="social-midias d-flex mt-2">
                      <a href="instagram.com" target="_blank">
                        <img src="images/social/instagram.svg" alt="Instagram" />
                      </a>
                      <a href="facebook.com" target="_blank">
                        <img src="images/social/facebook.svg" alt="Facebook" />
                      </a>
                    </div>
                  </div>
                  <div>
                    <img src="images/professores/estefanyburg.jpg" alt="Professor" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-lg-2 py-4">
                <div className="professor-card d-flex justify-content-between align-items-start">
                  <div className="professor-card-info">
                    <h4 className="font-quicksand--bold">Marcelo Brum</h4>
                    <span className="font-quicksand--normal">Funcional de areia</span>
                    <div className="social-midias d-flex mt-2">
                      <a href="instagram.com" target="_blank">
                        <img src="images/social/instagram.svg" alt="Instagram" />
                      </a>
                      <a href="facebook.com" target="_blank">
                        <img src="images/social/facebook.svg" alt="Facebook" />
                      </a>
                    </div>
                  </div>
                  <div>
                    <img src="images/professores/marcelobrum.jpg" alt="Professor" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-lg-2">
                <div className="professor-card d-flex justify-content-between align-items-start">
                  <div className="professor-card-info">
                    <h4 className="font-quicksand--bold">Alessandro de Assis</h4>
                    <span className="font-quicksand--normal">Beach Tennis</span>
                    <div className="social-midias d-flex mt-2">
                      <a href="instagram.com" target="_blank">
                        <img src="images/social/instagram.svg" alt="Instagram" />
                      </a>
                      <a href="facebook.com" target="_blank">
                        <img src="images/social/facebook.svg" alt="Facebook" />
                      </a>
                    </div>
                  </div>
                  <div>
                    <img src="images/professores/alessandrodeassis.jpg" alt="Professor" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <img className="endereco-wave" src={wave} alt="ondas" />
        </div>
        <FooterHome />
      </>
    );
  }
}

export default withRouter(Aulas);

import React from "react";
import { withRouter, Link } from "react-router-dom";
import { ButtonLaranja } from "../buttons/Buttons";
import { HashLink } from "react-router-hash-link";
import praia1 from '../../images/beach1.png'
import praia2 from '../../images/beach2.png'
import estrela from '../../images/beach3.png'
import { animateScroll as scroll } from 'react-scroll'

import "./footer.scss";


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [
        {
          name: "Serviços",
          anchor: "/#servicos",
        },
        {
          name: "Aulas",
          route: "/aulas",
        },
        {
          name: "Eventos",
          anchor: "/eventos"
        },
        // {
        //   name: "360°",
        //   anchor: "/#360",
        // },
        {
          name: "Apresentação",
          anchor: "/#apresentacao",
        },
        {
          name: "Endereço",
          anchor: "/#endereco",
        },
      ],
      socials: [
        {
          src: "/images/social/instagram.svg",
          alt: "Instagram",
          href: "https://www.instagram.com/arenapoolbeach/",
        },
        {
          src: "/images/social/facebook.svg",
          alt: "Facebook",
          href: "https://www.facebook.com/arenapoolbeach/#",
        },
        
        // {
        //   src: "./images/social/youtube.svg",
        //   alt: "Youtube",
        //   href: "www.youtube.com",
        // },
        // {
        //   src: "./images/social/twitter.svg",
        //   alt: "Twitter",
        //   href: "www.twitter.com",
        // },
      ],
      background: props.background,
      // openModal: false
    };
    // this.openModal = this.openModal.bind(this);
  }

 

  render = () => {

    const toggleHome = () => {
      scroll.scrollToTop()
    }
    
    return (
      <>
        <footer className="footer-home" style={{ backgroundColor: this.state.background ? this.state.background : "#FFEDAD" }}>
          <img className='footer-estrela' src={estrela} alt="estrela-mar" />
          <img className='footer-bola' src={praia1} alt="praia-bola" />
          <img className='footer-tapete' src={praia2} alt="praia-tapete" />
          {/* {this.state.openModal &&
            <Modal
              open={this.state.openModal}
              onClose={() => this.openModal(false)}
              onBackdropClick={() => this.openModal(false)}
              onEscapeKeyDown={() => this.openModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              body
            >
              <div className='text-center' style={{width:50}}>
                
                <img className='egg' src={require('../../images/Douglasvampeta.jpg')} onClick={() => this.openModal(false)} />
              </div>
            </Modal>
          } */}
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 mb-3">
                <div className="d-flex flex-column">
                  <div className="d-lg-flex flex-nowrap align-items-center logo">
                    <Link to="/">
                      <img src="/logo.png" alt="Logo" width="120" onClick={toggleHome} />

                    </Link>
                    <Link to="/area-comum/agendamento/" className="w-100">
                      <ButtonLaranja label="Reservar" className="btn-custom button-laranja font-quicksand--medium w-100" />
                    </Link>
                  </div>
                  <span className="font-quicksand--bold text-center">Siga a Arena nas redes sociais</span>
                  <div className="social-midias mt-4">
                    {this.state.socials.map((item, index) => (
                      <a key={index} target="_blank" href={item.href} rel="noopener noreferrer">
                        <img src={item.src} alt={item.alt} />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <nav className="mt-3 ml-lg-5">
                  {/* <h4 className="font-quicksand--bold"></h4> */}
                  <ul>
                    {this.state.menu.map((item, index) => (
                      <li key={index}>
                        {item.anchor ? (
                          <HashLink className="font-quicksand--medium" to={item.anchor}>
                            {item.name}
                          </HashLink>
                        ) : (
                          <Link className="font-quicksand--medium" to={item.route}>
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              <div className="col-lg-3 col-md-6">
                <nav className="mt-3 ml-lg-5">
                  {/* <h4 className="font-quicksand--bold"></h4> */}
                  <ul>
                    <li className="font-quicksand--medium">Tel.: (55) 99964-9172</li>
                    <li className="font-quicksand--medium w-100">End.: Av. América, 820 - Centro, Santa Rosa - RS</li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-4 col-md-12 p-lg-0">
                <div className="h-100 d-lg-flex align-items-end">
                  <p className="font-quicksand--normal text-sm-center">Arena Pool Beach, todos os direitos reservados {new Date().getFullYear()} ©</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter(Footer);

import React from "react";
import { withRouter, Link } from "react-router-dom";
import { ButtonLaranja } from "../buttons/Buttons";
import { NavBar } from "../navbar/NavBar";
import Video from '../../videos/loop.mp4'
import "./header.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="header-home vh-100">
          <video muted loop playsInline autoPlay preload="all" crossOrigin="anonymous" className="css-1oxbc12">
            <source className="vix" src={Video} type="video/mp4" />
          </video>
          <NavBar type="primary" />
          <div className="container h-100">
            <div className="row d-flex flex-column align-items-center justify-content-center h-100">
              <div className="col-md-12">
                <div className="wellcome d-flex flex-column align-items-center justify-content-center text-center h-75">
                  <h1 className="font-fredoka-one">Bem-vindo à sua casa de esportes de praia</h1>
                  <span className="font-quicksand--medium">É saúde. É diversão. É entretenimento</span>
                  <Link to="/area-comum/agendamento/">
                    <ButtonLaranja label="Reservar um horário" className="btn-custom button-laranja mt-5 font-quicksand--medium" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default withRouter(Header);

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ButtonLaranja, ButtonOutline } from "../buttons/Buttons";
import { HashLink } from "react-router-hash-link";

import "./navbar.scss";
import  Icon  from "../../components/Icons/Icons";

export function NavBar(props) {
  const [menu] = useState([
    {
      name: "Serviços",
      anchor: "/#servicos",
    },
    {
      name: "Aulas",
      route: "/aulas",
    },
    {
      name: "Eventos",
      anchor: "/eventos"
    },
    // {
    //   name: "360°",
    //   anchor: "/#360",
    // },
    {
      name: "Apresentação",
      anchor: "/#apresentacao",
    },
    {
      name: "Endereço",
      anchor: "/#endereco",
    },
  ]);

  const [type] = useState(props.type);

  const [collapsed, setCollapsed] = useState(true);

  const Li = () => {
    return menu.map((item, index) => (
      <li className="nav-item" key={index} onClick={() => setCollapsed(true)}>
        {item.anchor ? (
          <HashLink smooth className="nav-link font-quicksand--medium" to={item.anchor}>
            {item.name}
          </HashLink>
        ) : (
          <Link className="nav-link font-quicksand--medium" to={item.route}>
            {item.name}
          </Link>
        )}
      </li>
    ));
  };

  const NavItems = (props) => {
    return (
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto ml-md-5">{props.children}</ul>
        <NavButtons />
      </div>
    );
  };

  const NavMobile = () => {
    return (
      <div className="navbar-mobile animate__animated animate__fadeInDown animate__faster">
        <ul className="navbar-nav d-flex align-items-center justify-content-center h-100">
          <Li />
          <NavButtons />
        </ul>
      </div>
    );
  };

  const NavButtons = () => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Link to="/area-comum/agendamento/">
          <ButtonLaranja label="Reservar" className="btn-custom button-laranja mr-4 font-quicksand--medium" />
        </Link>

        <Link to="/login">
          <ButtonOutline label="Login" className="btn-custom button-outline font-quicksand--medium" />
        </Link>
      </div>
    );
  };

  const [config] = useState({
    logo: { primary: "/images/02_Arena_Pool_Beach.png", secondary: "/images/04_Arena_Pool_Beach.png" },
    togglerColor: {
      primary: { close: "#401f00", show: "#fff" },
      secondary: { close: "#401f00", show: "#401f00" },
    },
  });

  const NavDefault = () => {
    return (
      <nav className={"navbar navbar-expand-lg navbar-" + type}>
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={config.logo[type]} alt="Arena Pool Beach" width="100" />
          </Link>
          <div className={!collapsed ? "navbar-toggler m-0 close" : "navbar-toggler m-0"} type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <Icon icon={collapsed ? 'bars' : 'times'} prefix={'fal'} size="2x" color={collapsed ? config.togglerColor[type].show : config.togglerColor[type].close} onClick={() => setCollapsed(!collapsed)} />
          </div>
          <NavItems>
            <Li />
          </NavItems>
        </div>
      </nav>
    );
  };

  return (
    <>
      <NavDefault />
      {!collapsed && <NavMobile />}
    </>
  );
}

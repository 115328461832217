import React from "react"
import "./title.scss"

export class Title extends React.Component {
    render() {
        return (
            <label
                className={`txt1 font-quicksand--bold ${this.props.className} ${this.props.color}`}
                style={this.props.style}
            >
                {this.props.label}
            </label>
        )
    }
}

export class TitleStep extends React.Component {
    render() {
        return (
            <label
                className={"txt2 font-quicksand--bold mt-3 color-text-gradient" + this.props.className}
                style={this.props.style}
            >
                {this.props.label}
            </label>
        )
    }
}

export class Placeholder extends React.Component {

    render() {
        return (
            <label className="fontQuick" key={this.props.key} style={this.props.style} >{this.props.placeholder || this.props.children}</label>
        )
    }

}

export class Explanation extends React.Component {
    render() {
        return (
            <span className={`explanation ${this.props.color}`}>{this.props.label}</span>
        )
    }
}
import React from 'react'
import Loader from "../loaders/Loader"
import "./default.scss"

export class DefaultButton extends React.Component {
    render() {
        let { loading, labelLoading } = this.props
        return (
            <button
                style={this.props.style}
                className={`btn-custom default solid ${this.props.className} ${this.props.disabled ? "not-allowed disabled" : ""} `}
                data-toggle={this.props.datatoggle}
                aria-haspopup={this.props.ariahaspopup}
                aria-expanded={this.props.ariaexpanded}
                // {...this.props}
                onClick={this.props.onClick}
                type={this.props.type}
                disabled={loading ? true : this.props.disabled} >
                {this.props.children}
                {loading ?
                    <><Loader /> {labelLoading ? labelLoading : this.props.label} </>
                    : this.props.label}
            </button>
        )
    }
}

export class SecondaryButton extends React.Component {
    render() {
        let { loading, labelloading } = this.props
        return (
            <button
                style={this.props.style}
                className={this.props.className ? this.props.className : `secondary btn-custom color-text-gradient`}
                data-toggle={this.props.datatoggle}
                aria-haspopup={this.props.ariahaspopup}
                aria-expanded={this.props.ariaexpanded}
                // {...this.props}
                onClick={this.props.onClick}
                type={this.props.type}
                disabled={loading ? true : this.props.disabled} >
                {this.props.children}
                {loading ?
                    <><Loader /> {labelloading ? labelloading : this.props.label} </>
                    : this.props.label}
            </button>
        )
    }
}

export class ButtonBranco extends React.Component {
    render() {
        let { loading, labelloading } = this.props
        return (
            <button
                style={this.props.style}
                className={this.props.className ? this.props.className : `btn-acessar button-laranja`}
                data-toggle={this.props.datatoggle}
                aria-haspopup={this.props.ariahaspopup}
                aria-expanded={this.props.ariaexpanded}
                // {...this.props}
                onClick={this.props.onClick}
                type={this.props.type}
                disabled={loading ? true : this.props.disabled} >
                {this.props.children}
                {loading ?
                    <><Loader /> {labelloading ? labelloading : this.props.label} </>
                    : this.props.label}
            </button>
            )
    }
}

export class DangerButton extends React.Component {
    render() {
        let { loading, labelloading } = this.props
        return (
            <button
                style={this.props.style}
                className={this.props.className ? this.props.className : `secondary btn-custom color-text-gradient`}
                data-toggle={this.props.datatoggle}
                aria-haspopup={this.props.ariahaspopup}
                aria-expanded={this.props.ariaexpanded}
                // {...this.props}
                onClick={this.props.onClick}
                type={this.props.type}
                disabled={loading ? true : this.props.disabled} >
                {this.props.children}
                {loading ?
                    <><Loader /> {labelloading ? labelloading : this.props.label} </>
                    : this.props.label}
            </button>
        )
    }
}
export class ButtonLaranja extends React.Component {
    render() {
        return <button onClick={this.props.onClick} style={this.props.style} className={this.props.className ? this.props.className : `btn-custom button-laranja`}>{this.props.label}</button>;
    }
}

export class ButtonVerMais extends React.Component {
    render() {
        return <button onClick={this.props.onClick} style={this.props.style} className={this.props.className ? this.props.className : `btn-custom button-vermais`}>{this.props.label}</button>;
    }
}
export class ButtonOutline extends React.Component {
    render() {
        return (
          <button className={this.props.className ? this.props.className : `btn-custom button-outline`}>
            {this.props.label}
          </button>
        );
    }
}

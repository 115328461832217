import React from 'react'
import "./ComponentForm.scss"

export default class ComponentForm extends React.Component {


    render() {
        return (
            <form className={"form-custom " + this.props.className} onSubmit={this.props.onSubmit} style={this.props.style} >
                {this.props.children}
            </form>
        )
    }
} 
import React from "react"
import { withRouter, Link } from "react-router-dom"
import { Erro } from "../../components/texts/Erro";
import { setIn } from "final-form";
import * as yup from "yup"
import { TextField } from "../../components/inputs/InputsMasks";
import { Form, Field } from 'react-final-form';
import { DefaultButton, SecondaryButton } from "../../components/buttons/Buttons";
import ComponentForm from "../../components/forms/ComponentForm";
import { Placeholder, TitleStep } from "../../components/texts/Title"
import server from "../../services/api";
import TypeStatusReqs from "../../services/statusRequisicoes";
import { TypeToast } from "../../components/toast/TypeToast";

class TrocaSenha extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialValues: this.props.initialValues,
            trocado: false,
            recursos: [],
            schema: yup.object().shape({
                nome: yup.string(),
                cpf: yup.string(),
                email: yup.string(),
                nova_senha: yup.string()
                    .test('valid', 'A senha deve conter números e letras', function (value) {
                        return /^(?=.*[A-Za-z])(?=.*\d).+$/.test(value)
                    })
                    .min(6, 'A senha deve ter mais de 6 caracteres!')
                    .required('Senha obrigatório!'),
                passwordConfirmation: yup.string()
                    .oneOf([yup.ref('nova_senha'), null], 'As senhas não são as mesmas!')
                    .required('A confirmação da senha é necessária!'),

            }),
            loading: false
        }
    }

    componentDidMount() {
        const result = {}
        const token = window.location.search.slice(1).split('=')
        result[decodeURIComponent(token[0])] = decodeURIComponent(token[1]) || '';

        server.post("/user/alterar-senha", result)
            .then(
                (res) => { },
                (err) => {
                    TypeToast((err.response.data.message), "error");
                    this.props.history.push({
                        pathname: '/'
                    })
                }
            )
    }

    validate = async (values) => {
        try {
            await this.state.schema.validate(values, { abortEarly: false });
        } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
                return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
        }
    };

    handleSubmit = (object) => {
        const token = window.location.search.slice(1).split('=')
        object['token'] = decodeURIComponent(token[1]);

        this.setState({ loading: true })
        server
            .patch(`/user/change-password-sucess`, object)
            .then((res) => {
                // TypeToast(res.data.response, "success")
                // this.props.history.push("/user/login")
                this.setState({ loading: false, trocado: true })
            }, (err) => {
                this.setState({ loading: false })
                TypeToast(TypeStatusReqs(err), "error")
            })
    }

    render() {
        return (
            <section className="container" style={{ width: '80%', margin: '55px auto 0' }}>
                <Form
                    onSubmit={this.handleSubmit}
                    validate={this.validate}
                    render={({ handleSubmit }) => (
                        <ComponentForm onSubmit={handleSubmit} className="row justify-content-between">
                            {this.state.trocado ?
                                <>
                                    <div className='w-100'>
                                        <TitleStep label='Senha redefinida' />
                                    </div>
                                    <div className='mt-3'>
                                        <Placeholder>Sua senha foi redefinida</Placeholder>
                                    </div>
                                    <div className='w-100'>
                                        <div className="pt-5 text-right">
                                            <Link to='/login'>
                                                <DefaultButton label="Confirmar" loading={this.state.loading} />
                                            </Link>
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <div className="mb-4 col-12 p-0">
                                        <div className="w-100">
                                            <TitleStep
                                                label="Troca de Senha"
                                            />
                                        </div>
                                        <Placeholder placeholder='Agora vamos cadastrar sua nova senha' />
                                    </div>
                                    <Field name="nova_senha">
                                        {({ input, meta }) => (
                                            <div className="mb-5 col-lg-5 col-12 p-0">
                                                <TextField
                                                    placeholder="Senha"
                                                    {...input}
                                                    type="password"
                                                    id={"password"}
                                                />
                                                <Erro
                                                    touched={meta.touched}
                                                    error={meta.error && meta.error}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="passwordConfirmation">
                                        {({ input, meta }) => (
                                            <div className="mb-5 col-lg-5 col-12 p-0">
                                                <TextField
                                                    placeholder="Confirmação de senha"
                                                    {...input}
                                                    type="password"
                                                    id={"passwordConfirmation"}
                                                />
                                                <Erro
                                                    touched={meta.touched}
                                                    error={meta.error && meta.error}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    <div className="row col-12 text-center mt-4 p-0">
                                        <div className="form-group col-md-6 col-12">
                                            <SecondaryButton
                                                type="button"
                                                label="Voltar"
                                                onClick={() => { this.props.history.push("/login") }}
                                            />
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <DefaultButton label="Atualizar" labelloading="Atualizando" loading={this.state.loading} />
                                        </div>
                                    </div>
                                </>}
                        </ComponentForm>
                    )}
                />
            </section>
        )
    }
}

export default withRouter(TrocaSenha)
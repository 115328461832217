import React from 'react';
import { Form, Field } from 'react-final-form';
import { setIn } from 'final-form';
import * as yup from 'yup';
import { withRouter, Link } from "react-router-dom";
import { CPF } from '../../components/inputs/InputsMasks';
import { DefaultButton, SecondaryButton } from "../../components/buttons/Buttons"
import server from "../../services/api";
// import { login } from '../../services/auth';
import { Erro } from '../../components/texts/Erro';
import { Placeholder, TitleStep } from '../../components/texts/Title';
import { TypeToast } from '../../components/toast/TypeToast';
import TypeStatusReqs from '../../services/statusRequisicoes';
import "../../styles/body.scss"


class EsqSenha extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            schema: yup.object().shape({
                cpf: yup.string().test("validar_cpf", "CPF inválido", (strCPF) => {
                    if (!strCPF)
                        return false;

                    if (strCPF.length >= 11) {
                        return true
                    }
                }).required("CPF é obrigatório").nullable(true),
            }),
            loading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    validate = async (values) => {
        try {
            await this.state.schema.validate(values, { abortEarly: false });
        } catch (err) {
            const errors = err.inner.reduce((formError, innerError) => {
                return setIn(formError, innerError.path, innerError.message);
            }, {});
            return errors;
        }
    };


    handleSubmit(object) {
        const newObject = this.state.schema.cast(object);
        this.setState({ loading: true })
        server
            .post("/user/esqueceu-a-senha", newObject)
            .then(
                res => {
                    this.props.history.push({
                        pathname: '/confirme-email',
                        state: {
                            email: res.data.email,
                            cpf: res.data.cpf
                        }
                    })

                }, (err) => {
                    if (err.response.data.status && err.response.status === 404) { //Verificar questão do status do erro
                        TypeToast(TypeStatusReqs("CPF não encontrado"), "error")
                    } else {
                        TypeToast(TypeStatusReqs(err), "error")
                    }
                })

            .finally(() => this.setState({ loading: false }))
    }
    render() {
        return (
            <div style={{ width: '70%', backgroundColor: '#fff', margin: '55px auto 0', padding: '3rem', borderRadius: '8px' }}>
                <Form
                    onSubmit={this.handleSubmit}
                    validate={this.validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="fixed-top text-left mb-sm-4 mb-2" style={{ zIndex: -1, right: "4%" }}>
                                <Link to='/'>
                                    <img
                                        src="/logo.png"
                                        alt="logo"
                                        style={{ maxWidth: "120px" }}
                                    />
                                </Link>
                            </div>
                            <div>
                                <div className='w-100'>
                                    <TitleStep label="Recuperação de senha" />
                                </div>
                                <Placeholder
                                    placeholder='Digite o seu CPF para podermos lhe enviar um e-mail com o link para a recuperação da sua senha.'
                                />
                            </div>
                            {this.state.msg ? <div className="alert alert-danger text-center">
                                {this.state.msg}
                            </div> : null}
                            <Field name="cpf">
                                {({ input, meta }) => (
                                    <div className="mb-5 mt-5 col-lg-6 p-0">
                                        <CPF
                                            {...input}
                                            id="cpf"
                                            name="cpf"
                                            type="tel"
                                            semMasck={true}
                                        />
                                        <Erro
                                            touched={true}// tem que dá uma olhada.
                                            error={meta.error}
                                        />
                                    </div>
                                )}
                            </Field>
                            <div className="d-flex flex-row justify-content-between pt-5">
                                <Link to="/login"><SecondaryButton type='button' label="Cancelar" /></Link>
                                <DefaultButton label="Continuar" type={'submit'} loading={this.state.loading} />
                            </div>
                        </form>
                    )}
                />
            </div>
        )
    }

}

export default withRouter(EsqSenha);
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import Loading from './components/loaders/Loading';
import { isAuthenticated } from "./services/auth";
import Login from "./views/login/Login";
import EsqSenha from "./views/login/esqSenha";
import ContainerToast from './components/toast/ToastContainer';
import Home from "./views/home/Home";
import Aulas from "./views/aulas/Aulas";
import Eventos from "./views/eventos/Eventos"
import EventosCompleto from './views/eventos/EventosCompleto';
import EmailRecuperacao from './views/login/confirmEmail'
import RedefinirSenha from './views/login/redefinirSenha';

const App = loadable({
  loader: () => import('./App'),
  loading: Loading,
});

const Agendamento = loadable({
  loader: () => import('./views/formularios/Agendamento'),
  loading: Loading,
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const RenderLogin = () => (
  (isAuthenticated() ? <Redirect to={{ pathname: "/dashboard", state: { from: "/login" } }} /> : <Login />)
)



const Routes = () => (
  <BrowserRouter>
    <ContainerToast />
    <Switch>
      <Route exact path="/login" component={() => <RenderLogin />} />
      <Route exact path="/esqueceu-a-senha" component={() => isAuthenticated() ? <Redirect to={{ pathname: '/dashboard', state: { from: '/esqueceu-a-senha' } }} /> : <EsqSenha />} />
      <Route exact path="/confirme-email" component={() => isAuthenticated() ? <Redirect to={{ pathname: '/dashboard', state: { from: '/confirme-email' } }} /> : <EmailRecuperacao />} />
      <Route exact path="/redefinir-senha" component={() => isAuthenticated() ? <Redirect to={{ pathname: '/dashboard', state: { from: '/redefinir-senha' } }} /> : <RedefinirSenha />} />

      <Route exact path="/" component={Home} />
      <Route exact path="/aulas" component={Aulas} />
      <Route exact path="/area-comum/agendamento/" component={Agendamento} />
      <Route exact path="/eventos" component={Eventos} />
      <Route exact path="/eventos/:id" component={EventosCompleto} />
      <PrivateRoute path="/*" component={App} />
    </Switch>
  </BrowserRouter>
);

export default Routes;

import React from 'react';

export default function Icon({
  icon,
  p,
  pr,
  pl,
  pt,
  pb,
  size,
  prefix,
  className,
  onClick,
  color,
  cursor,
  type,
}) {
  return (
    <i
      className={`${prefix ?? 'fas'} fa-${icon} ${className} fa-${size}`}
      onClick={onClick}
      style={{
        padding: p,
        paddingLeft: pl,
        paddingRight: pr,
        paddingTop: pt,
        paddingBottom: pb,
        color: color,
        cursor: cursor,
      }}
    ></i>
  );
}

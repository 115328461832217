import React from "react"
import "./formOption.scss"

export default class FormOption extends React.Component {

    render() {
        return (
            <div className="col-10 m-auto">
                <div className="row main-container">
                    <div className="col-md-6 left-container ml-auto mr-auto p-5 central-vertical">
                        <form onSubmit={this.props.onSubmit}>
                            {this.props.children}
                        </form>
                    </div>
                    <div className="module-border-wrap mr-auto central-vertical col-md-4 p-1">
                        <div className="rigth-container p-5 central-vertical mr-auto">
                            <div>
                                {this.props.cabecalho}
                            </div>
                            <div className="text-center">
                                {this.props.rodape}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
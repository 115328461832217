import React from "react";
import "./eventosCompleto.scss";
import wave from "../../images/v1.svg";
import server from "../../services/api";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router-dom";
import FormatDate from "../../utils/formatDate";
import { NavBar } from "./../../components/navbar/NavBar";
import FooterHome from "./../../components/footer/Footer";
import { TypeToast } from "../../components/toast/TypeToast";
import TypeStatusReqs from "../../services/statusRequisicoes";
import Icon from "../../components/Icons/Icons";
import InfiniteScroll from "../../components/infinteScroller/InfiniteScroller";

class Eventos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {},
            loading: false,
            showModal: false,
            imgIndex: 0,
            albumSize: 0,
        };
        this.nextImage = this.nextImage.bind(this);
        this.prevImage = this.prevImage.bind(this);
        this.searchEventos = this.searchEventos.bind(this)
    }
    componentDidMount() {
        this.searchEventos({ page: 1 })
    }
    searchEventos({ page }) {
        server.get(`/eventos/${this.props.match.params.id}`, { params: { page: page } }).then(
            (res) => {
                this.setState({
                    initialValues: {
                        nome: res.data.nome,
                        descricao: res.data.descricao,
                        data: FormatDate({ date: res.data.data, pattern: "dd-MM-yyyy" }),
                        album: res.data.album,
                        banner: res.data.banner,
                    },
                    albumSize: res.data.albumSize,
                    loading: false,
                });
            },
            (err) => {
                this.setState({ loading: false });
                TypeToast(TypeStatusReqs(err), "error");
                this.props.history.push("/eventos");
            }
        );
    }
    nextImage() {
        this.setState({ imgIndex: this.state.imgIndex + 1 });
    }
    prevImage() {
        this.setState({ imgIndex: this.state.imgIndex - 1 });
    }

    render() {
        return (
            <>
                <div className="eventos">
                    <NavBar type="secondary" />
                    <div className="container">
                        {this.state.loading ? (
                            <div className="wrapper">
                                <div className="c-item c1"></div>
                                <div className="c-item c2"></div>
                                <div className="c-item c3"></div>
                                <div className="c-item c4"></div>
                            </div>
                        ) : (
                            <>
                                <div className="coluna-evento mt-5">
                                    <div className="evento-imagem">
                                        <img
                                            src={this.state.initialValues?.banner}
                                            alt={`Banner da evento ${this.state.initialValues?.nome}`}
                                            style={{ borderRadius: "16px" }}
                                        />
                                    </div>
                                    <div className="evento-texto">
                                        <article>
                                            <div>
                                                <span className="font-16">
                                                    <span className="evento-data-tipo--bold">
                                                        {this.state.initialValues?.data}
                                                    </span>
                                                </span>
                                            </div>
                                            <h1 className="font-quicksand--bold color-text-gradient  mt-3 mb-0">
                                                {this.state.initialValues?.nome}
                                            </h1>
                                            <div
                                                className="mt-5 text-break font-quicksand--medium"
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.initialValues?.descricao,
                                                }}
                                            />
                                        </article>
                                    </div>
                                </div>
                                {this.state.initialValues?.album &&
                                    this.state.initialValues?.album?.length > 0 && (
                                        <div className="evento-imgs-texto mt-5">
                                            <div className="titles">
                                                <span className="font-quicksand--bold">
                                                    Mais imagens...
                                                </span>
                                                <InfiniteScroll
                                                    albumSize={this.state.albumSize}
                                                    currentSize={this.state.initialValues?.album.length}
                                                    search={this.searchEventos}
                                                >
                                                    <div className="evento-imgs">
                                                        {this.state.initialValues?.album?.map((img, idx) => {
                                                            return (
                                                                <div
                                                                    className="m-1 col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0"
                                                                    key={idx}
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            showModal: true,
                                                                            imgIndex: idx,
                                                                        })
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        width="100%"
                                                                        height="250px"
                                                                        src={img.url}
                                                                        alt={`Mais imagens ${idx + 1}`}
                                                                        style={{
                                                                            borderRadius: "16px",
                                                                            objectFit: "cover",
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </InfiniteScroll>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                        <img className="endereco-wave" src={wave} alt="ondas" />
                    </div>

                    <Modal
                        centered={true}
                        show={this.state.showModal}
                        // onHide={() => this.setState({ showModal: false })}
                        animation={true}
                        size="lg"
                    >

                        <Modal.Header>
                            <h5 className="modal-title">Album de imagens do evento</h5>
                            <button
                                onClick={() => this.setState({ showModal: false })}
                                type="button"
                                className="btn-close close p-1"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon
                                    icon={'times-circle'}
                                    size="2x"
                                    color="#FF6900"
                                />
                            </button>
                        </Modal.Header>

                        <Modal.Body>
                            <div
                                id="carouselExampleDark"
                                className="carousel carousel slide"
                                data-bs-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    {this.state.initialValues?.album?.map((img, idx) => {
                                        const active = idx === this.state.imgIndex;
                                        return (
                                            <div
                                                className={`carousel-item ${active ? "active" : ""}`}
                                                key={idx}
                                            >
                                                <img
                                                    src={img.url}
                                                    style={{
                                                        width: "60vh",
                                                        height: "60vh",
                                                        objectFit: "contain",
                                                    }}
                                                    className="d-block w-100"
                                                    alt={img.key}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                {this.state.imgIndex + 1 > 1 && (
                                    <button
                                        style={{ background: "transparent", border: 0 }}
                                        onClick={this.prevImage}
                                        className="carousel-control-prev"
                                        type="button"
                                        data-bs-slide="prev"
                                    >
                                        <Icon
                                            icon={'arrow-alt-circle-left'}
                                            size="2x"
                                            color="#FF6900"
                                        />
                                    </button>
                                )}
                                {this.state.initialValues?.album?.length >
                                    this.state.imgIndex + 1 && (
                                        <button
                                            style={{ background: "transparent", border: 0 }}
                                            onClick={this.nextImage}
                                            className="carousel-control-next"
                                            type="button"
                                            data-bs-slide="next"
                                        >
                                            <Icon
                                                icon={'arrow-alt-circle-right'}
                                                size="2x"
                                                color="#FF6900"
                                            />
                                        </button>
                                    )}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <FooterHome />
            </>
        );
    }
}
export default withRouter(Eventos);

export const stub = {
  servicos: [
    {
      src: require('../../images/quadra.jpg'),
      name: "Quadras de areia",
      hasButton:true,
      description: "Todo dia é dia de praia sim! Com nossas duas quadras, Praia Norte e Praia Sul, garantimos para você a prática de beach tennis, vôlei, futevôlei, beach câmbio, treino funcional. Faça chuva ou faça sol! Reserve agora e garanta seu horário.",
      id:"5fdbad950e5e672a49d0beea"
    },
    {
      src: require('../../images/churrasqueira2.jpg'),
      name: "Churrasqueiras",
      hasButton:true,
      description: "Existe coisa melhor que churrasco na praia? Existe sim, churrasco, praia e nenhuma preocupação, só relaxar e aproveitar. Oferecemos toda essa comodidade para você, é só reservar e chamar a família e os amigos. Diversão garantida.",
      id:"5feb422d7df2761988481c90"
    },
    {
      src: require('../../images/aula1.jpg'),
      name: "Aulas",
      description: "Só de pensar em praia já deu aquela vontade de pisar na areia né? Mas ainda não sabe por onde começar, ou está com dúvida sobre a prática esportiva? Venha fazer uma de nossas aulas e conheça mais sobre o beach sport.",
      hasButton:true,
      id:"",
      link:'/aulas'
    },
    {
      src: require('../../images/sauna.png'),
      name: "Sauna",
      description: "Depois de suar na areia ou então ao fim de mais um dia de trabalho, todos merecemos relaxar e ter um tempo dedicado à nós mesmos. Para isso, um tempo na nossa sauna, para desligar da rotina e recarregar todas as energias. Venha conhecer!",
    },
    {
      src: require('../../images/espacokids.png'),
      name: "Espaço kids",
      description: "Um lugar especial, pensado e dedicado à elas, as crianças. Já pensou, poder ir à praia e poder ter a certeza que seu filho estará brincando e se divertindo em um lugar seguro? Venha conferir nosso espaço para eles.",
    },
    {
      src: require('../../images/coronaBar.png'),
      name: "Corona bar",
      description: "Aquele famoso after! Seja no pós jogo, no pós treino ou no happy hour com os amigos, assistir seu time... Uma cerveja gelada, um bate papo com os amigos, um ambiente agradável. Ele foi criado para confraternizar! Venha conferir.",
    },
    {
      src: require('../../images/store.jpg'),
      name: "Arena Store",
      description: "Para aqueles que já são amantes do beach sport e querem fazer bonito nas quadras, disponibilizamos os uniformes oficiais da Arena Pool Beach. Conheça nossa store e venha você também fazer parte do nosso time!",
    },
    {
      src: require('../../images/game.jpg'),
      name: "Arena Games",
      description: "Vem aí um novo espaço para você, sua família e seus amigos. Um espaço de puro entretenimento, diversão, transmissão de jogos, bilhar, aero hockey e muito mais! Em breve a Arena Games Stella Artois, fique ligado e não perca!",
    },
  ],
};

import React from "react";
import "./loading.scss"
export default class Loading extends React.Component {
    render() {
        let { props } = this;
        if (props.error) {
            return (
                <div>
                    Ops! Ocorreu um erro{" "}
                    <button className="btn btn-default" onClick={props.retry}>
                        Tente novamente
          </button>
                </div>
            );
        } else if (props.pastDelay) {
            return (
                <div className="wrapper">
                    <div className="c-item c1"></div>
                    <div className="c-item c2"></div>
                    <div className="c-item c3"></div>
                    <div className="c-item c4"></div>
                </div>
            );
        } else {
            return null;
        }
    }
}

type patternTypes = "HH:mm" | "dd-MM-yyyy" | "dd/MM/yyyy" | "yyyy-MM-dd HH:mm" | "yyyy/MM/dd HH:mm" | "dd/MM/yyyy HH:mm" | "yyyy-MM-dd" | "yyyy/MM/dd" | "ddMMyyyy" 
type localeTypes = "pt-BR";
type timezoneTypes = "America/Sao_Paulo";
interface IFormatUtcToZonedTime {
  date: Date | number | string;
  pattern?: patternTypes;
  timezone: localeTypes;
  options?: IOptionsDateFormat;
}
enum localeToTimezone {
  "pt-BR" = "America/Sao_Paulo",
}
interface IOptionsDateFormat {
  weekday?: "narrow" | "short" | "long";
  era?: "narrow" | "short" | "long";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  literal?: "-";
  timeZone: timezoneTypes;
}

export default function formatDate({
  date,
  pattern,
  timezone = "pt-BR",
  options,
}: IFormatUtcToZonedTime) {

  let defaultOptions: IOptionsDateFormat = {
    timeZone: localeToTimezone[timezone as localeTypes] as timezoneTypes,
  };
  
  if (typeof date === "string") {
    date = Date.parse(date);
  }
  
  if (options) {
    return new Intl.DateTimeFormat(timezone, options).format(date);
  }
  
  switch (pattern) {
    case "HH:mm":
      defaultOptions = {
        ...defaultOptions,
        hour: "2-digit",
        minute: "2-digit",
      };
      break;
    case "yyyy/MM/dd HH:mm":
    case "dd/MM/yyyy HH:mm":
    case "yyyy-MM-dd HH:mm":
      defaultOptions = {
        ...defaultOptions,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      break;
    case "ddMMyyyy":
    case "yyyy/MM/dd":
    case "yyyy-MM-dd":
    case "dd/MM/yyyy":
    case "dd-MM-yyyy":
      defaultOptions = {
        ...defaultOptions,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
      break

    default:
      return new Error("invalid pattern");
  }

  let formatDate = new Intl.DateTimeFormat(timezone, defaultOptions).format(date);

  switch (pattern) {
    case "yyyy-MM-dd":
      formatDate = formatDate.substring(0, 10)
        .split("/")
        .reverse()
        .join("-");
      break
    case "yyyy/MM/dd":
      formatDate = formatDate.substring(0, 10)
        .split("")
        .reverse()
        .join("");
      break
    case "yyyy/MM/dd HH:mm":
      const hour = formatDate.substring(10, 17);
      formatDate = formatDate.substring(0, 10)
        .split("")
        .reverse()
        .join("");
      formatDate += hour
      break
    case "dd/MM/yyyy HH:mm":
      const hourAux = formatDate.substring(11, 17)
      formatDate = formatDate.substring(0, 10)
      formatDate += hourAux
      break
    case "yyyy-MM-dd HH:mm":
      const dateHour = formatDate.substring(10, 17);
      formatDate = formatDate.substring(0, 10)
        .split("/")
        .reverse()
        .join("-");
      formatDate += dateHour
      break
    case "dd-MM-yyyy":
      formatDate = formatDate.replaceAll("/", "-")
      break
    case "ddMMyyyy":
      formatDate = formatDate.replaceAll("/", "")
      break
    // default:
    //   return new Error("invalid pattern");
  }

  return formatDate
}

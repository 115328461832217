const TypeStatusReqs = (obj) => {
    let resposta, err = obj.response
    if (err)
        switch (err.status) {
            case 400: resposta = err.data ? err.data : "A requisição não pode ser feita."; break;
            case 401: resposta = err.data ? err.data : "Você não tem autorização para fazer essa requisição."; break;
            case 403: resposta = err.data ? err.data : "O serviço não pode disponibilizar esses dados."; break;
            case 404: resposta = "O serviço não encontrou uma resposta válida para a sua requisição."; break;
            case 405: resposta = err.data ? err.data : "Requisição não pode ser feita, devido as permissões."; break;
            case 500: resposta = "Desculpa estamos com problemas para processar seus dados nesse momento."; break;
            default: resposta = err.data ? err.data : "Ops, ocorreu um erro não identificado"; break;
        }
    else
        resposta = "Ação feita não teve uma resposta, tente novamente mais tarde"
    return resposta
}

export default TypeStatusReqs

import React from 'react';

class InfiniteScroll extends React.Component {
    //recebe de props o search para buscar, a quantidade total de items sem paginação e a quantidade de itens atual 
    constructor(props) {
        super(props);
        this.loaderRef = React.createRef()
        this.state = {
            page: 1,
        };
    }
    componentDidMount() {
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }
        this.observer = new IntersectionObserver(this.handleObserver.bind(this), options)
        if (this.props.albumSize > this.props.currentSize)
            this.observer.observe(this.loaderRef)
    }
    handleObserver(entities) {
        const y = entities[0].boundingClientRect.y;
        if (this.state.prevY > y) {
            var nextPage = this.state.page + 1
            this.setState({
                page: nextPage
            })
            this.props.search({ page: nextPage })
        }
        this.setState({ prevY: y });
    }
    render() {

        return (
            <div>
                {this.props.children}
                {this.props.albumSize > this.props.currentSize &&
                    <div style={{ textAlign: 'left', marginTop: "10px" }}>
                        <p ref={loaderRef => (this.loaderRef = loaderRef)}>Carregando mais...</p>
                    </div>
                }
            </div>
        )
    }
}

export default InfiniteScroll;
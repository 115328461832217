import { toast } from "react-toastify"
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import "./toast.scss"
export const TypeToast = (
    message: "",
    types: (| "info" | "success" | "warning" | "error" | "default" | "dark") = "default",
    position: (| "top-left" | "top-right" | "top-center" | "bottom-left" | "bottom-right" | "bottom-center") = "top-right",
    transition: (| "Slide" | "Zoom" | "Flip" | "Bounce") = "Flip",
    delay: 500
): any => {
    let trans, type
    switch (transition) {
        case "Slide": trans = Slide; break;
        case "Zoom": trans = Zoom; break;
        case "Flip": trans = Flip; break;
        case "Bounce": trans = Bounce; break;
    }

    switch (types) {
        case "info": type = toast.info; break;
        case "success": type = toast.success; break;
        case "warning": type = toast.warning; break;
        case "error": type = toast.error; break;
        case "default": type = toast; break;
        case "dark": type = toast.dark; break;
        default: break;
    }

    return type ? type(message, {
        position: position,
        autoClose: delay,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: trans
    }) : null;

};

import "./eventos.scss";
import React from "react";
import wave from "../../images/v1.svg";
import server from "../../services/api";
import FormatDate from "../../utils/formatDate";
import { withRouter, Link } from "react-router-dom";
import { NavBar } from "./../../components/navbar/NavBar";
import FooterHome from "./../../components/footer/Footer";
import { TypeToast } from "../../components/toast/TypeToast";
import TypeStatusReqs from "../../services/statusRequisicoes";
import { ButtonLaranja } from "../../components/buttons/Buttons";

class Eventos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventos: [],
      loading: false,
      hasMore: 1,
      page: 1
    };
  }
  componentDidMount() {
    this.buscarEventos();
  }
  buscarEventos() {
    this.setState({ loading: true });
    server.get(`/eventos?page=${this.state.page}`).then(
      (res) => {
        this.setState({
          eventos: [...this.state.eventos, ...res.data[0]?.data || res.data],
          loading: false,
          msg: res.data?.length <= 0 ? 'Nenhum Evento Registrado' : "",
          hasMore: res.data[0]?.paging?.pages || 1
        });
      }, (err) => {
        this.setState({ loading: false });
        TypeToast(TypeStatusReqs(err), "error");
      }
    );
  }

  render() {
    return (
      <>
        <div className="eventos" style={{ minHeight: '75vh' }}>
          <NavBar type="secondary" />
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-3">
                <div className="titles">
                  <h3 className="w-5 font-quicksand--bold color-text-gradient">
                    {this.state.msg
                      ? this.state.msg
                      : 'Eventos'}
                  </h3>
                </div>
              </div>
            </div>
            {this.state.loading
              ? <div className="wrapper">
                <div className="c-item c1"></div>
                <div className="c-item c2"></div>
                <div className="c-item c3"></div>
                <div className="c-item c4"></div>
              </div>
              : <div className="row">
                {this.state.eventos?.filter((item, idx) => idx < 2)?.map((evento) => (
                  <div className="col-12 col-md-6 eventos-grande-container mt-4" key={evento?._id}>
                    <Link className="no-decoration" to={`/eventos/${evento?._id}`}>
                      <div className="p-4 eventos--grande position-relative" style={{ backgroundImage: `url(${evento?.banner})`, backgroundSize: 'cover', minHeight: 328, backgroundRepeat: 'no-repeat' }}>
                        <div className="position-absolute w-100 h-100 gradiente-leitura" style={{ borderRadius: '16px' }}></div>
                        <div style={{ zIndex: 99 }}>
                          <span className="eventos-data-tipo">{FormatDate({ date: evento.data, pattern: 'dd/MM/yyyy' })}</span>
                          <h2 className="eventos-link">{evento?.nome}</h2>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                {this.state.eventos.filter((item, idx) => idx > 1)?.map((evento) => (
                  <div className="col-12 col-lg-6 mt-5 evento-pequena-container" key={evento?._id}>
                    <Link className="no-decoration" to={`/eventos/${evento?._id}`}>
                      <div className="d-flex eventos-mobile">
                        <img className="imagem-evento--pequena" src={evento?.banner} alt={`Banner Evento ${evento?.nome}`} />
                        <div className="evento-pequena-texto">
                          <span className="eventos-pequenas-data-tipo eventos-fonte--pequena-bold">{FormatDate({ date: evento.data, pattern: 'dd/MM/yyyy' })}</span>
                          <h3 className="eventos-fonte--media line-clamp-2 mt-2 font-weight-bold">{evento?.nome}</h3>
                          <div className="eventos-fonte--pequena line-clamp-3" dangerouslySetInnerHTML={{ __html: evento.descricao }}></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            }
          </div>
          {this.state.hasMore !== this.state.page ?
            <div className="button-centered mt-5">
              <ButtonLaranja
                label="Mais Eventos"
                className="btn-custom button-laranja font-quicksand--medium"
                onClick={() => this.setState({ page: this.state.page + 1 }, () => this.buscarEventos())}
              />
            </div>
            : null}
          <img className="endereco-wave" src={wave} alt="ondas" />
        </div>
        <FooterHome />
      </>
    );
  }
}

export default withRouter(Eventos);
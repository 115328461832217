import axios from 'axios';
import { getToken } from './auth';
// import { getToken, getRefreshToken, logout, login } from './auth'; se houver uma rota para pegar novo token

const server = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000'
});

// Adiciona o token em todas as requisições que usam esta instância do axios, caso não deva enviar, usar a instancia padrão dele
server.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

//Adiciona um interceptor nas requisições para ver houve um erro do back, caso seja 401 chuta o cara pro login
// api.interceptors.response.use(
//   function (response) {
//     //Qualquer status que esteja dentro dos "200" vai entrar nessa função
//     return response;
//   },
//   function (error) {
//     if (error.response.status === 401) {
//       console.log(error.response);
//       if (error.response.data.erro.indexOf('jwt expired') > -1) {
//         api.post('refresh', { refresh: getRefreshToken() }).then(
//           (res) => {
//             login({ token: res.data.token });
//           },
//           (err) => {
//             logout();
//             window.location.href = '/login';
//           }
//         );
//       } else {
//         logout();
//         window.location.href = '/login';
//       }
//     }
//     // Outros status caem aqui
//     return Promise.reject(error);
//   }
// );

export default server;
